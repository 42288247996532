const fontFamily = [
  {
    name: 'Default',
    class: 'Default',
  },
  {
    name: 'Inter',
    class: 'Inter',
  },
  {
    name: 'Comic Sans',
    class: 'Comic Sans MS, Comic Sans',
  },
  {
    name: 'Serif',
    class: 'serif',
  },
  {
    name: 'Monospace',
    class: 'monospace',
  },
  {
    name: 'Cursive',
    class: 'cursive',
  },
]

export default fontFamily
